import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Slider from "./Slider/Slider"; // Assuming you have a Slider component

const ActivityDetail = ({ data }) => {
    console.log(data)
    if (!data) return <p className="text-center">Loading...</p>;

    return (
        <div className="container pt-3">
            <h2 className='text-center title2 mb-3' style={{ color: 'black' }}>{data.title}</h2>
            <div className="align-items-center">
                <Slider className="col-12 w-full" height={550} photos={data.images} />
                <div className='py-3 text-center mt-2'>
                    {data.body && <div dangerouslySetInnerHTML={{ __html: data.body }} style={{ color: '#575757' }} />}
                </div>
            </div>
        </div>
    );
};

const ActivityDetailPage = () => {
    const { id } = useParams();
    const [activity, setActivity] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActivity = async () => {
            try {
                const response = await fetch(`https://backend.streamline.edu.mm/api/activities`);
                const data = await response.json();
                 // Find the activity that matches the given id
                 const selectedActivity = data.find(activity => String(activity.id) === String(id));

                 setActivity(selectedActivity);
            } catch (error) {
                console.error("Error fetching activity:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchActivity();
    }, [id]);

    if (loading) return <p className="text-center">Loading...</p>;
    if (!activity) return <p className="text-center">Activity not found.</p>;

    return <ActivityDetail data={activity} />;
};

export default ActivityDetailPage;
