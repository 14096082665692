import { useEffect, useState } from "react";
import Slider from "./Slider/Slider";

const AnnouncementAndActivities = () => {
    const [activities, setActivities] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchActivities = async () => {
            try {
                const response = await fetch("https://backend.streamline.edu.mm/api/activities");
                const data = await response.json();
                setActivities(data);
            } catch (error) {
                console.error("Error fetching activities:", error);
            } finally {
                setLoading(false);
            }
        };
        fetchActivities();
    }, []);

    if (loading) return <p className="text-center">Loading...</p>;

    return (
        <div className="container py-5 w-full">
            <h2 className='text-center title1' style={{ color: 'black' }}>Announcement and Activities</h2>
            <div className="row justify-content-center align-items-top pt-5">
                {activities.map(activity => (
                    <div key={activity.id} className='mb-4 col-12 col-lg-4 mb-4' style={{ overflow: 'hidden', objectFit: 'contain'}}>
                        <Slider className="col-12" style={{ height: '500px' }} photos={activity.images} />
                        <div className='py-3'>
                            <h4 style={{ fontSize: '20px', fontWeight: '500', lineHeight: '28px' }}>{activity.title}</h4>
                            {activity.body && (
                                <p className="line-clamp" dangerouslySetInnerHTML={{ __html: activity.body }} />
                            )}
                            <a className='text-white text-decoration-none' href={`/AnnouncementAndActivities/${activity.id}`}>
                                <button className="button-87" role="button">Read More</button>
                            </a>
                        </div>
                    </div>
                ))}
            </div>
            {/* CSS Styling for Multi-line Ellipsis */}
            <style jsx>{`
                .line-clamp {
                    display: -webkit-box;
                    -webkit-line-clamp: 8; /* Change to 4 or 5 lines as needed */
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    color: #575757;
                    min-height: 120px; /* Adjust based on your line height */
                }
            `}</style>
        </div>
    );
};

export default AnnouncementAndActivities;
